import React from "react";
import LoginPage from "./Pages/LoginPage";
import { Routes, Route } from "react-router-dom";
import Reservation from "./Pages/Reservation";
import Payments from "./Pages/Payments";
import PrivateRoute from "./Components/PrivateRoute";
import AddInventory from "./Pages/AddInventory";
import Toolbar from "./Components/Toolbar";
import InventoryList from "./Pages/InventoryList";
import ResetPassword from "./Pages/ResetPassword";
import Discount from "./Pages/Discount"
import DiscountRules from "./Pages/DiscountRules";

class App extends React.Component {
  render() {
    return (
      <div>
        {localStorage.getItem("token") && <Toolbar/>}
        <Routes>
          <Route path="/" exact element={<LoginPage />} />
          <Route path="/reservation" exact element={<PrivateRoute><Reservation /></PrivateRoute>} />
          <Route path="/payments" exact element={<PrivateRoute><Payments /></PrivateRoute>} />
          <Route path="/add-inventory" exact element={<PrivateRoute><AddInventory /></PrivateRoute>} />
          <Route path="/inventory" exact element={<PrivateRoute><InventoryList /></PrivateRoute>} />
          <Route path="/discount" exact element={<PrivateRoute><Discount /></PrivateRoute>} />
          <Route path="/apply-discount-rules" exact element={<PrivateRoute><DiscountRules /></PrivateRoute>} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Routes>
      </div>
    )
  }
}


export default App;